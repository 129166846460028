<template>
  <el-tag
    v-bind="{ ...$attrs, ...props }"
    effect="dark"
    :class="['ui-tag', `ui-tag--${props.color}`]"
  >
    <template #default>
      <slot name="default"></slot>
    </template>
  </el-tag>
</template>
<script setup lang="ts">
import { tagProps } from 'element-plus'
export type TagColor =
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'textTertiary'
  | 'sell'
  | 'buy'
  | 'tag'
  | 'error'
  | 'success'

const props = defineProps({
  ...tagProps,
  color: {
    type: String as PropType<TagColor>,
    default: 'tag',
  },
  round: {
    type: Boolean,
    default: true,
  },
})
</script>
<style scoped lang="postcss">
.ui-tag.el-tag {
  --el-tag-bg-color: transparent;
  --el-tag-hover-color: transparent;
  --el-tag-border-radius-rounded: 0.8125rem; /* 13px */
  --el-tag-font-size: 0.75rem; /* 12px */

  height: auto;
  padding: 0.25rem 0.625rem; /* 4px 10px */
  font-family: var(--font-family-primary);
  font-weight: 400;
  line-height: 1rem; /* 16px */
  transition: none;
}
.ui-tag.el-tag.ui-tag--primary {
  --el-tag-text-color: var(--brand-color-primary);
  --el-tag-border-color: var(--brand-color-primary);
}
.ui-tag.el-tag.ui-tag--secondary {
  --el-tag-text-color: var(--brand-color-secondary);
  --el-tag-border-color: var(--brand-color-secondary);
}
.ui-tag.el-tag.ui-tag--textPrimary {
  --el-tag-text-color: var(--brand-color-text-primary);
  --el-tag-border-color: var(--brand-color-text-primary);
}
.ui-tag.el-tag.ui-tag--textSecondary {
  --el-tag-text-color: var(--brand-color-text-secondary);
  --el-tag-border-color: var(--brand-color-text-secondary);
}
.ui-tag.el-tag.ui-tag--textTertiary {
  --el-tag-text-color: var(--brand-color-text-tertiary);
  --el-tag-border-color: var(--brand-color-text-tertiary);
}
.ui-tag.el-tag.ui-tag--sell {
  --el-tag-text-color: var(--brand-color-sell);
  --el-tag-border-color: var(--brand-color-sell);
}
.ui-tag.el-tag.ui-tag--buy {
  --el-tag-text-color: var(--brand-color-buy);
  --el-tag-border-color: var(--brand-color-buy);
}
.ui-tag.el-tag.ui-tag--tag {
  --el-tag-text-color: var(--brand-color-tag);
  --el-tag-border-color: var(--brand-color-tag);
}
.ui-tag.el-tag.ui-tag--error {
  --el-tag-text-color: var(--brand-color-error);
  --el-tag-border-color: var(--brand-color-error);
}
.ui-tag.el-tag.ui-tag--success {
  --el-tag-text-color: var(--brand-color-success);
  --el-tag-border-color: var(--brand-color-success);
}
</style>
